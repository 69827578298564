div[class$='MuiDataGrid-root'] {
  border: 0;
  overflow-x: auto;
  .MuiDataGrid-virtualScrollerRenderZone {
    .MuiDataGrid-row {
      border: 1px solid #ddd;
      width: 100%;
      height: 74px !important;
      margin-bottom: 10px;
      border-radius: 4px;
      margin-right: -3px;
      .MuiDataGrid-cell {
        border-bottom: 0;
        &:focus,
        &:focus-within {
          outline: none !important;
        }
      }
    }
  }
  div[class$='MuiDataGrid-columnHeaders'] {
    border-bottom: 0;
  }
}
