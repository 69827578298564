@import './assets/scss/core';

.editor {
  .ql-toolbar {
    height: 45px;
  }
  .ql-container {
    height: 350px;
  }
  height: 395px;
  // color: :aquamarine;
}
.dropdown {
  &.org-dropdown {
    margin-left: 10px;
    margin-right: 10px;
    @media only screen and (min-width: 900px) {
      width: 20%;
    }
    @media only screen and (max-width: 900px) {
      width: 50%;
    }
    @media only screen and (max-width: 600px) {
      width: 100%;
    }
    div[class$='control'] {
      padding: 0px 8px;
    }
    color: #333 !important;
  }
  &.language-dropdown {
    margin-left: 10px;
    margin-right: 10px;
    @media only screen and (min-width: 900px) {
      width: 20%;
    }
    div[class$='control'] {
      padding: 0px 8px;
    }
    color: #333 !important;
  }
  &.status-drodown,
  &.selectAssignee {
    width: 150px;
    font-size: 12px;
    font-weight: 500;
    div[class$='control'] {
      padding: 0px 8px;
    }
  }
  &.selectAssignee {
    width: 230px;
  }
}
.OPEN {
  .dropdown {
    // &:first-child {
    //   border: 2px solid #dfe1e6;
    //   background-color: #dfe1e6 !important;
    // }

    div[class$='control'] {
      border-color: #dfe1e6;
      background-color: #dfe1e6;
    }

    svg,
    input {
      color: #333;
    }

    div[class$='singleValue'] {
      color: #333;
    }
  }
}

.IN_PROGRESS {
  .dropdown {
    // &:first-child {
    //   border: 2px solid #0052cc;
    //   background-color: #0052cc !important;
    // }

    div[class$='control'] {
      border-color: #0052cc;
      background-color: #0052cc;
    }

    svg,
    input {
      color: #fff;
    }

    div[class$='singleValue'] {
      color: #fff;
    }
  }
}
.preview {
  border: 1px solid #ddd;
  padding: 10px;
  border-radius: 4px;
  margin-top: 0;
  min-height: 80vh;
}
.ScrollbarsCustom-Track {
  top: 0 !important;
}
.MuiAlert-message {
  overflow-wrap: anywhere;
}
.no_data {
  text-align: center;
  align-items: center;
  justify-content: center;
  display: flex;
  min-height: 200px;
  font-weight: 500;
  font-size: 20px;
}
.last-row div button:last-child {
  // display: none;
  padding-right: 0;
}
.MuiDataGrid-virtualScroller {
  overflow-y: auto !important;
  min-height: 200px;
}
