.profile-name{
    margin: 0px;
    @media (max-width: 600px) {
        font-weight: 600;
        font-size: 1.4rem;
    }
    @media (min-width: 600px) {
        font-weight: 600;
        font-size: 1.5rem;
    }
    line-height: 1.334;
}
.profile-info{
    margin: 0px;
    font-weight: 500;
    font-size: 1rem;
}