.dropdown {
    position: relative;
  div[class$='control'] {
    padding: 9px 8px;
  }
  .labels {
    position: absolute;
    top: -7px;
    z-index: 1;
    font-size: 12px;
    left: 8px;
    background: #fff;
    padding: 0 10px;
  }

  &.mainError{
    div[class$='control'] {
      border-color: #d32f2f;
    }
    div[class$='placeholder'] {
      color: #d32f2f;
      
    }
  }
}
div[class$='menu'] {
z-index: 9;
}