$primary: #1ca7b1;
$secondary: #ffd44f;
$white: #ffffff;
$border: #ebedf0;
$green: #28a745;

// header
$headerLogo: $primary;
$headerBorder: $white;
$headerBackground: $white;
$headerText: #333333;

// sidebar
$sidebarText: #42526e;
$sidebarActiveText: #0052cc;
$sidebarBackground: $white;
$sidebarActiveBackground: $white;
$sidebarHover: #cadefc;
$sidebarLevel2Background: $white;

// table
$tableHeaderText: $primary;
$tableHeaderBackground: #f5f9fa;
$tableRowText: #333333;

$filterBadgeText: #fcf6e4;
$filterBadgeBackground: #ffc700;
